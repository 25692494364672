import React from 'react'
import './Card.scss'
import {
    Button,
    ClickableTile,
    DismissibleTag as DismissibleTagComponent,
    Heading,
    Layer,
    Stack,
    Tag,
    Tile,
    Toggletip,
    ToggletipButton,
    ToggletipContent
} from '@carbon/react'
import PropTypes from 'prop-types';
import Icon from '../../Collections/Icon'
import Divider from '../../Collections/Divider'
const Card = ({
    border = 'subtle',
    padding = '05',
    style,
    tags,
    background = 2,
    href,
    children,
    icon,
    iconArtworkStyle,
    iconSize = '32px',
    iconColor = 'interactive',
    iconAlt,
    iconGradient,
    iconGradientColor,
    heading,
    contentType,
    footer,
    headingSize = 'xs',
    headingContextTypeSize = 's',
    DismissibleTag = false,
    button,
    buttonProps,
    ...rest
}) => {
    const ElementTag = href ? ClickableTile : Tile
    const TagType = DismissibleTag ? DismissibleTagComponent : Tag
    const hrefCheck = href && { href: href }
    const borderCheck =
        border !== 'none' ? { border: `1px solid var(--cds-spark-theme-border-${border}-01)` } : null
    const layerCheck = background
        ? { '--cds-spark-tile-bg': `var(--cds-spark-theme-layer-${background})` }
        : null

    const cardStyle = {
        padding: `var(--cds-spark-theme-spacing-${padding})`,
        ...borderCheck,
        ...layerCheck
    }

    const cardProps = {
        style: {
            ...cardStyle,
            ...style
        },
        ...hrefCheck,
        ...rest
    }

    return (
        <ElementTag {...cardProps}>
            <Stack gap={5}>

                <Layer style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}>
                    {icon && (<Icon
                        icon={icon}
                        artworkStyle={iconArtworkStyle}
                        color={iconColor}
                        size={iconSize}
                        altText={iconAlt}
                        gradient={iconGradient}
                        gradientColor={iconGradientColor}
                        style={{ paddingRight: 'var(--cds-spark-theme-spacing-03)' }}
                    />)}
                    <Heading className={`spark-text-size-${headingContextTypeSize}`}
                        style={{
                            color: 'var(--cds-text-secondary) !important',
                            textTransform: 'uppercase'
                        }}>
                        {contentType}
                    </Heading>
                </Layer>

                <Layer style={{ padding: 'var(--cds-spark-theme-spacing-03) 0' }}>
                    {heading && (
                        <Heading className={`spark-heading-size-${headingSize}`}>{heading}</Heading>)}
                    {tags && (
                        <Layer>
                            <Stack gap={2} orientation="horizontal" className="display-block">
                                {tags.map((item, idx) => (
                                    <>
                                        {item.toggletip ? (
                                            <Toggletip key={idx} autoAlign>
                                                <ToggletipButton>
                                                    <Tag style={{ marginInlineStart: '0px' }}>
                                                        ...
                                                    </Tag>
                                                </ToggletipButton>
                                                <ToggletipContent className="popover-bg-color">
                                                    <Layer as="div">
                                                        {item.toggletip.map((tipItem, idx) => (
                                                            <Tag key={idx} {...tipItem}>
                                                                {tipItem.text}
                                                            </Tag>
                                                        ))}
                                                    </Layer>
                                                </ToggletipContent>
                                            </Toggletip>
                                        ) : (
                                            <TagType key={idx} {...item}>
                                                {item.text}
                                            </TagType>
                                        )}
                                    </>
                                ))}
                            </Stack>
                        </Layer>
                    )}
                    <Layer className='spark-text-size-m' style={{ paddingTop: 'var(--cds-spark-theme-spacing-03)', color: 'var(--cds-text-primary)' }}>
                        {children}
                    </Layer>
                    {button && (
                        <Layer style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                iconDescription="Icon description"
                                kind="ghost"
                                hasIconOnly
                                renderIcon={() => <Icon color="interactive" icon="arrow-large-right" />}
                                {...buttonProps}
                            />
                        </Layer>
                    )}
                </Layer>

                {footer && (<Layer className='spark-text-size-s' style={{ color: 'var(--cds-text-primary)' }}>
                    <Divider spacing='04' />
                    {footer}
                </Layer>)
                }
            </Stack>
        </ElementTag>
    )
}

Card.propTypes = {
    padding: PropTypes.oneOf([
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13'
    ]),
    border: PropTypes.oneOf(['none', 'subtle', 'strong']),
    background: PropTypes.oneOfType([
        PropTypes.oneOf([1, 2, 3]),
        PropTypes.string
    ]),
    className: PropTypes.string,
    style: PropTypes.object,
    href: PropTypes.string,
    target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top', '_unfencedTop']),
    tags: PropTypes.arrayOf(PropTypes.object),
    DismissibleTag: PropTypes.bool,
    icon: PropTypes.string,
    iconArtworkStyle: PropTypes.oneOf(['solid', 'regular', 'light']),
    iconSize: PropTypes.string,
    iconColor: PropTypes.oneOf(['primary', 'secondary', 'disabled', 'interactive', 'inverse']),
    iconAlt: PropTypes.string,
    heading: PropTypes.string,
    contentType: PropTypes.string,
    footer: PropTypes.string,
    headingSize: PropTypes.oneOf(['2xl', 'xl', 'l', 'm', 'ms', 's', 'xs']),
    headingContextTypeSize: PropTypes.oneOf(['2xl', 'xl', 'l', 'm', 'ms', 's', 'xs']),
    children: PropTypes.node,
    button: PropTypes.bool,
    buttonProps: PropTypes.object,
    iconGradient: PropTypes.bool,
    iconGradientColor: PropTypes.string
};
export default Card
