import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../src/components/HomePage';
import Header from '../src/components/common/Header';
import Footer from '../src/components/common/Footer';
import Overview from './components/Overview';
import SoftwareCatalog from './components/SoftwareCatalog';
import PageNotFound from './components/ErrorPage/pageNotFound';
import SelectorToolAppWrapper from './selector-tool-ui-src/SelectorToolAppWrapper';
import { loginRequest } from './authConfig';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser'; 
import Collections from './components/Collections';
import { metroCards, metroCollection, metroTagArray, retailCollection, retailTagArray } from './common/utility';

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/software-catalog" element={<SoftwareCatalog />} />
          <Route path="/collection/metro-ai" element={<Collections cards={metroCards} collection={metroCollection} tagArray={metroTagArray}/>} />
          <Route path="/collection/retail-ai" element={<Collections cards={[]} collection={retailCollection} tagArray={retailTagArray}/>} />
          <Route path="/details" element={<Overview />} />
          <Route path="/package/*" element={
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
              <SelectorToolAppWrapper />
            </MsalAuthenticationTemplate>
          } />
          <Route path="/PageNotFound" element={<PageNotFound />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
