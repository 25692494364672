import React from 'react';
import PropTypes from 'prop-types';

export const Text = ({
    size = 'l',
    color = 'secondary',
    className,
    style,
    children,
    ...rest
}) => (
    <span
        className={`spark-text-size-${size} ${className}`}
        style={{ color: `var(--cds-text-${color})`, ...style }}
        {...rest}
    >
        {children}
    </span>
);
Text.propTypes= {
    /** Specifies a className for the element */
    className: PropTypes.string,
    /** Specifies inline styles for the element. */
    style: PropTypes.object,
    /** Title for the sidebarblock. The first block usually doesn't have a title. */
    children: PropTypes.node,
    color: PropTypes.oneOf(['primary' , 'secondary' , 'disabled' , 'placeholder']),
    size: PropTypes.oneOf(['l' , 'm' , 's' , 'xs'])
}
