import "./CatalogCard.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePageNumber } from "../../redux/reducers/slice/filterSlice";
import { PaginationNav, Column, Grid} from "@carbon/react"
import Card from "../common/CardUsability"
import {encodeURIComponentModified, formatDate, getDescription, getIcon, getName} from "../../common/utility"
const {
    catalogPagination: { itemsPerPage }
} = require("../../constants/config");
// function getTextFromHtml(html) {
//     const doc = new DOMParser().parseFromString(html, "text/html");
//     return doc.body.innerText ?? "";
// }
const CatalogCard = (props) => {
    const [dataList, setDataList] = useState(props.data);
    const [paginatedList, setPaginatedList] = useState([]);
    const currentPage = useSelector(
        (state) => state.filter.filter.currentPageNumber
    );
    const dispatch = useDispatch();

    useEffect(() => {
        setDataList(props.data);
        dispatch(changePageNumber(1));
    }, [props.data]);

    useEffect(() => {
        const indexOfLastCard = currentPage * itemsPerPage;
        const indexOfFirstCard = indexOfLastCard - itemsPerPage;
        setPaginatedList(dataList.slice(indexOfFirstCard, indexOfLastCard));
    }, [currentPage, dataList]);

    const handleGotoPage = (pageNumber) => {
        dispatch(changePageNumber(pageNumber + 1));
    };
    return (
        <>
        
            <Grid condensed={true} style={{ gap: '16px', padding: 0 }} >
                {paginatedList.map((item) => (
                    <Column lg={4} md={2} sm={4}>
                        <Card
                            heading={getName(item.displayName)}
                            contentType={item.contentType}
                            icon={getIcon(item.contentType)}
                            iconGradient
                            iconGradientColor="linear-gradient(
                                                180deg,
                                              #5400C0,
                                              #057aff,
                                              #16cfb1
                                               )"
                            children={getDescription(item.microserviceDescription)}
                            footer={formatDate(item.modifiedOn)}
                            href={`/details/?microserviceType=${item.microserviceType}&microserviceNameForUrl=${encodeURIComponentModified(item.microserviceNameForUrl)}`} target="_blank"
                        />
                    </Column> 
                ))}
           </Grid>
          
            <PaginationNav
                className="content_pagination"
                size="md"
                totalItems={Math.ceil(dataList.length/itemsPerPage)}
                page={currentPage - 1}
                pageSize={itemsPerPage}
                onChange={(n) => {
                     handleGotoPage(n);
                }}
            />
           
        </>
    );
};

export default CatalogCard;
