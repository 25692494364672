import { useState, useEffect } from 'react';
import './CollectionBlade.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Column } from '@carbon/react';
import Card from "../common/CardUsability"
import { getDescription, getIcon, getName } from "../../common/utility"

const CollectionBlade = () => {
    const [sliderSettings, setSliderSettings] = useState({});
    const isMobile = window.innerWidth <= 672;
    const isTablet = window.innerWidth > 672 && window.innerWidth <= 1024;

    const cards = [
        {
            heading: 'Metro AI Suite',
            contentType:'Collection',
            description: 'The Metro AI Suite is an application framework with libraries, tools, and reference implementations, enabling partners to create AI solutions in the Video Safety and Security, Transportation, and Government Edge markets.',
            collectionRoute:'/collection/metro-ai',
            modifiedOn:'Updated: 03/28/2025'            
        },
        {
            heading: 'Intel® Retail AI Suite',
            contentType:'Collection',
            description: 'The Intel® Retail AI Suite is an open-source application framework designed to accelerate AI workload evaluation and hardware selection for point-of-sale use cases at the edge.',
            collectionRoute:'/collection/retail-ai',
            modifiedOn:'Updated: 3/13/2025'                 
        }
    ];

    const CustomRightArrow = (props) => {
        const { onClick } = props;
        // onMove means if dragging or swiping in progress.

        return (
            <>
                <i onClick={() => onClick()} className="spark-icon spark-icon-light spark-icon-chevron-right slide-arrow next-arrow"></i>
            </>
        )

    };
    const CustomLeftArrow = (props) => {
        const { onClick } = props;
        // onMove means if dragging or swiping in progress.
        return (
            <>
                <i onClick={() => onClick()} className="spark-icon spark-icon-light spark-icon-chevron-left slide-arrow prev-arrow"></i>
            </>
        )

    };

    useEffect(() => {
        const handleResize = () => {

            const settings = {
                dots: false,
                infinite: cards.length > 4,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: cards.length > 4 ? 4 : 0,
                arrows: cards.length > 4 && !isMobile && !isTablet,
                prevArrow: <CustomLeftArrow />,
                nextArrow: <CustomRightArrow />,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 0,
                            slidesToScroll: 0,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 0,
                            arrows: false,
                        }
                    }
                ]
            };
            setSliderSettings(settings);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <>
            <div className="enterprise-ai-software-blade">
                <div className="carousel-section">
                    <div className="enterprise-ai-software-blade-title-section">
                        <p className="enterprise-ai-software-blade-title-content">Edge AI Suites</p>
                        <p className="regular-Body-L-Subtitle-M-font-size-200">
                            {'Accelerate solution development with curated and Intel-optimized components for your industry.'}
                        </p>
                    </div>
                <div className="project-grid">                      
                            { (window.innerWidth > 1024 && cards.length > 4) ? <>
                                <Slider className='project-grid' {...sliderSettings}>

                                    {cards.slice(0, 8).map((item, index) => (
                                        <>
                                            <Card
                                                heading={getName(item.heading)}
                                                contentType={item.contentType}
                                                icon={getIcon(item.contentType)}
                                                iconGradient
                                                iconGradientColor="linear-gradient(
                                                                    180deg,
                                                                    #5400C0,
                                                                    #057aff,
                                                                    #16cfb1
                                                                    )"
                                                children={getDescription(item.description)}
                                                footer={item.modifiedOn}
                                                href={item.collectionRoute} target="_blank"
                                            />
                                        </>
                                    ))}

                                </Slider></> : <Grid condensed={true} style={{ gap: '16px', padding: 0 }}> {cards.slice(0, 4).map((item, index) => (
                                    <>
                                        <Column lg={4} md={4} sm={4}>
                                            <Card
                                                heading={getName(item.heading)}
                                                contentType={item.contentType}
                                                icon={getIcon(item.contentType)}
                                                iconGradient
                                                iconGradientColor="linear-gradient(
                                                                    180deg,
                                                                    #5400C0,
                                                                    #057aff,
                                                                    #16cfb1
                                                                    )"
                                                children={getDescription(item.description)}
                                                footer={item.modifiedOn}
                                                href={item.collectionRoute} target="_blank"
                                            />
                                        </Column>
                                    </>
                                ))}</Grid>}                            
                    </div>
                </div>
            </div>

        </>


    );
};

export default CollectionBlade;
