import { useState, useEffect, useRef } from 'react';
import './Overview.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { CodeSnippet, FlexGrid, Row, Section, Button, Loading, Tag, Dropdown, Grid, Column, Stack, Heading, Link } from '@carbon/react';
import { overviewPageService } from '../../services/overviewPageService';
import parse from 'html-react-parser';
import Header from '../common/Header';
import { HeroBanner } from '../common/HeroBanner';
import { Text } from '../common/Text'
import  Sidebar from '../common/Sidebar';
import { getIcon, downloadFile  } from "../../common/utility"
import FoundationalComponent from '../common/FoundationalComponent';
const Overview = (props) => {

  const [onPageLoad, setOnPageLoad] = useState(false);
  const [edgeServiceId, setEdgeServiceId] = useState('');
  const [edgeServiceDetails, setEdgeServiceDetails] = useState({});
  const [overviewContent, setOverviewContent] = useState('');
  const [howItWorksContent, setHowItWorksContent] = useState('');
  const [updatedDate, setUpdatedDate] = useState("");
  const [multipleVersions, setMultipleVersions] = useState([]);
  const [showGetStarted, setShowGetStarted] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  // const [showGitHubUrl, setShowGitHubUrl] = useState(false);
  const [showDocumentation, setShowDocumentation] = useState(false);
  const [showUserGuide, setShowUserGuide] = useState(false);
  const [showAPIDocLink, setShowAPIDocLink] = useState(false);
  const [showBenchmarkLink, setShowBenchmarkLink] = useState(false);
  const [githubLanguages, setGithubLanguages] = useState({});
  const [currentVersion, setCurrentVersion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptyResponse, setIsEmptyResponse] = useState(false);
  const [currentVersionObject, setCurrentVersionObject] = useState();

  const overviewRef = useRef();
  const howItWorksRef = useRef();
  const builtWithRef = useRef();

   // Function to scroll to a specific section
   const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // let location = useLocation();
  const search = useLocation().search;
  const navigate = useNavigate();

  const callFunctions = async (microserviceType, microserviceNameForUrl) => {

    //Check if microserviceType and microserviceNameForUrl is present
    if (microserviceType !== undefined && microserviceType !== null && microserviceNameForUrl !== undefined && microserviceNameForUrl !== null) {

      //Get the Edge Service Id
      setIsLoading(true);
      let edgeServicesMetadataResponse = await overviewPageService.getEdgeServiceMetadata(microserviceType, microserviceNameForUrl);
      let data;

      if (edgeServicesMetadataResponse.status === 200) {
        if (edgeServicesMetadataResponse.data) {
          data = edgeServicesMetadataResponse.data;

          if (data.length > 0) {
            data.forEach(element => {

              if (element.microserviceType === 'helm') {
                let versionObject = {
                  id: element.id,
                  text: element.chartVersion
                }

                //Check if version already present
                let versionExists = false;
                multipleVersions.forEach(item => {
                  if (element.chartVersion === item.text) {
                    versionExists = true;
                  }
                })

                if (!versionExists) {
                  multipleVersions.push(versionObject);
                }
              }
              else if (element.microserviceType === 'container') {
                let versionObject = {
                  id: element.id,
                  text: element.imageTag
                }

                //Check if version already present
                let versionExists = false;
                multipleVersions.forEach(item => {
                  if (element.imageTag === item.text) {
                    versionExists = true;
                  }
                })

                if (!versionExists) {
                  multipleVersions.push(versionObject);
                }
              }
              else if (element.microserviceType === 'recipe') {
                let versionObject = {
                  id: element.id,
                  text: element.version
                }

                //Check if version already present
                let versionExists = false;
                multipleVersions.forEach(item => {
                  if (element.version === item.text) {
                    versionExists = true;
                  }
                })

                if (!versionExists) {
                  multipleVersions.push(versionObject);
                }
              }


            });
            setCurrentVersion(multipleVersions[0].text);
            setEdgeServiceId(multipleVersions[0].id);
            //setCurrentVersionObject({ id: multipleVersions[0].id, text: multipleVersions[0].text });
            setCurrentVersionObject(multipleVersions[0]);
            await getContents(multipleVersions[0].id);
          }
          else if (data.length === 0) {

            setIsEmptyResponse(true);
            setIsLoading(false);
            navigate("/PageNotFound");

          }
        }
        else {
          setIsEmptyResponse(true);
          setIsLoading(false);
          navigate("/PageNotFound");
        }
      }
      else {
        setIsLoading(false);
        navigate("/PageNotFound");
      }

    }

  }

  const updateVersionAndGetDetails = async (event) => {
    multipleVersions.forEach(element => {
      if (element.id === event.selectedItem.id) {
        setEdgeServiceId(event.selectedItem.id);
        setCurrentVersionObject(element);
        setCurrentVersion(element.text);

      }
    })
    await getContents(event.selectedItem.id);
  }

  const getContents = async (edgeServiceId) => {

    setIsLoading(true);
    let responseContents = await overviewPageService.getEdgeServiceContentsUsingId(edgeServiceId);

    if (responseContents.status === 200) {
      const data = responseContents.data;
      setIsLoading(false);
      if (data) {

        setEdgeServiceDetails(data);
        setOverviewContent(data.content.overview);
        setHowItWorksContent(data.content.howItWorks);

        const modifiedOn = data.modifiedOn.split('T')[0];
        const date = modifiedOn.split('-');
        const year = date[0];
        const month = date[1];
        const day = date[2];
        const dateFormat = month + '/' + day + '/' + year;
        setUpdatedDate(dateFormat);
        const selectorToolRecipeId = (data) => {
          const lastSlashIndex = data.downloadLink.split('/')[3];
          return '/package/' + lastSlashIndex;
        }
        console.log('selectorToolRecipeId(data)', selectorToolRecipeId(data))
        setEdgeServiceDetails((edgeServiceDetails) => ({ ...edgeServiceDetails, selectorToolRecipeId: selectorToolRecipeId(data) }))
        //Check if Download and Github link are provided
        // if (data.publicGithubUrl === "" || data.publicGithubUrl === null || data.publicGithubUrl === undefined) {
        //   setShowGitHubUrl(false);
        // }
        // else {
        //   setShowGitHubUrl(true);
        //   setShowGetStarted(true);
        // }

        if (data.downloadLink === "" || data.downloadLink === null || data.downloadLink === undefined) {
          setShowDownload(false);
        }
        else {
          setShowDownload(true);
          setShowGetStarted(true);
        }

        //Check for documentation
        if ((data.userGuideLink === "" || data.userGuideLink === undefined || data.userGuideLink === null) && (data.apiDocumentationLink === "" || data.apiDocumentationLink === undefined || data.apiDocumentationLink === null) && (data.benchmarkLink === "" || data.benchmarkLink === undefined || data.benchmarkLink === null)) {
          setShowDocumentation(false);
        }
        else if ((data.userGuideLink !== "" && data.userGuideLink !== undefined && data.userGuideLink !== null) || (data.apiDocumentationLink !== "" && data.apiDocumentationLink !== undefined && data.apiDocumentationLink !== null) || (data.benchmarkLink !== "" || data.benchmarkLink !== undefined || data.benchmarkLink !== null) ) {
          setShowDocumentation(true);
        }


        if (data.userGuideLink === "" || data.userGuideLink === undefined || data.userGuideLink === null) {
          setShowUserGuide(false);
        }
        else {
          setShowUserGuide(true);
        }

        if (data.apiDocumentationLink === "" || data.apiDocumentationLink === undefined || data.apiDocumentationLink === null) {
          setShowAPIDocLink(false);
        }
        else {
          setShowAPIDocLink(true);
        }
        if (data.benchmarkLink === "" || data.benchmarkLink === undefined || data.benchmarkLink === null) {
          setShowBenchmarkLink(false);
        }
        else {
          setShowBenchmarkLink(true);
        }


        await getGithubLanguages(edgeServiceId);
      }
      else {
        navigate("/PageNotFound");
      }
    }
    else {
      navigate("/PageNotFound");
    }



  }

  const getGithubLanguages = async (id) => {
    let gitResponse = await overviewPageService.getGithubLanguages(id);
    setGithubLanguages(gitResponse.data.languageInfo);
  }

  useEffect(() => {

    const params = new URLSearchParams(search);
    let microserviceType;
    let microserviceNameForUrl;
    if (params) {
      if (params.get('microserviceType')) {
        microserviceType = params.get('microserviceType');
      }
      else {
        navigate("/PageNotFound");
      }
      if (params.get('microserviceNameForUrl')) {
        microserviceNameForUrl = params.get('microserviceNameForUrl');
      }
      else {
        navigate("/PageNotFound");
      }
    }

    callFunctions(microserviceType, microserviceNameForUrl);


  }, [onPageLoad])

  const handleBackClick = () => {
    navigate(`/software-catalog`);
  };
  const headerBannerComponent = <HeroBanner>
    <Stack gap={1}>
      <Heading style={{
        color: 'var(--cds-text-inverse)'
      }} size="l">
        {edgeServiceDetails.displayName}
      </Heading>
      <Stack gap={1} orientation="horizontal" className='content-tags'>
        <Tag size="sm" className='overview_icon_tag'> <i
            className={`spark-icon spark-icon-${getIcon(edgeServiceDetails.contentType)} spark-icon-light overview_icon`}
            style={{ fontFamily: 'spark-icon', fontSize: '24px' }}
        />
        </Tag>
        {edgeServiceDetails.contentType && edgeServiceDetails.contentType.length > 0 ? (
          <Tag size="sm">
            {edgeServiceDetails.contentType}
          </Tag>
        ) : null}
        {edgeServiceDetails.industry && edgeServiceDetails.industry.length > 0 ? <>{edgeServiceDetails.industry.map((tag, index) => (
          (<Tag size="sm">
            {tag}
          </Tag>
          )

        ))}</>
          : null}
        {edgeServiceDetails.domain && edgeServiceDetails.domain.length > 0 ? <>{edgeServiceDetails.domain.map((tag, index) => (

          <Tag size="sm">
            {tag}
          </Tag>
        ))}</>
          : null}
      </Stack>
    </Stack>
  </HeroBanner>

  const menuMobileComponent = <Grid style={{
    rowGap: 'var(--cds-spark-theme-spacing-01)'
  }} className="mobile-menu">
    <nav style={{
      width: 'max-content'
    }}>
      <Column lg={16} md={8} sm={4}>
        <Link href="#overview">Overview</Link>
      </Column>
      <Column lg={16} md={8} sm={4}>
        <Link href="#getStarted">How to get started</Link>
      </Column>
      {(edgeServiceDetails.builtWith && edgeServiceDetails.builtWith.length > 0) ?
        <Column lg={16} md={8} sm={4}>
          <Link href="#builtwith">Built with Components</Link>
        </Column> : null}
    </nav>
  </Grid>;
  const menuTab = <nav className="desktop-menu">
    <Button kind='secondary' as="a" role='button' onClick={() => scrollToSection(overviewRef)}>Overview</Button>
    <Button kind='secondary' as="a" role='button' onClick={() => scrollToSection(howItWorksRef)}>
      How It Works
    </Button>
    {(edgeServiceDetails.builtWith && edgeServiceDetails.builtWith.length > 0) ?
      <Button kind='secondary' as="a" role='button' onClick={() => scrollToSection(builtWithRef)}>Built with Components</Button> : null}
  </nav>;
  const overviewComponent = <Grid style={{
    gap: 'var(--cds-spark-theme-spacing-05)'
  }}>
    <Column lg={{
      span: 12
    }} md={5} sm={4}>
      <Stack gap={5}>
        <Section level={2} className="section-top-gap" ref={overviewRef} id="overview">
          <Heading color='primary' size="m">Overview</Heading>
          <Stack gap={5}>
            {parse(overviewContent)}
          </Stack>
        </Section>

        <Section level={3} className="section-top-gap" ref={howItWorksRef} id="howitworks">
          <Heading color='primary' size="s">How it works</Heading>
          <Stack gap={5}>
            {parse(howItWorksContent)}
          </Stack>
        </Section>
      </Stack>
    </Column>
    <Column lg={{
      span: 4
    }} md={3} sm={4}>
      <aside>
        <Section level={2} className="section-top-gap" id="getStarted">
          <Heading className="screenreader">How to Get Started</Heading>
          <Sidebar>
            <Stack className="sidebar-content-stack">
              <Text size="m" color="secondary">
                Author
              </Text>
              <Text size="m" color="primary">
                Intel
              </Text>
            </Stack>

            <Stack className="sidebar-content-stack">
              <Text size="m" color="secondary">
                Updated
              </Text>
              <Text size="m" color="primary">
                {updatedDate}
              </Text>
            </Stack>

            <Stack className="sidebar-content-stack">
              <Text size="m" color="secondary">
                Version
              </Text>
              <Dropdown aria-label="version-dropdown" id="version" className='top-block-right-sidebar-project-info-list-item-text-dropdown-value' size='md' itemToString={(item) => (item ? item.text : '')} items={multipleVersions} onChange={updateVersionAndGetDetails} initialSelectedItem={multipleVersions[0]} selectedItem={currentVersionObject} />
            </Stack>
          </Sidebar>
          {showGetStarted ? 
              (edgeServiceDetails.recipeClass && edgeServiceDetails.recipeClass === 'Open Source Solution') ? <Sidebar title="Get Started" titleLevel={3}>
            {edgeServiceDetails.dockerComposeLink && edgeServiceDetails.dockerComposeLink.length > 0 && edgeServiceDetails.dockerComposeDocumentation && edgeServiceDetails.dockerComposeDocumentation.length > 0 ? <Stack className="sidebar-content-stack">
              <label className="spark-heading-size-xs">Compose file</label>
              <Text>
                <span>
                  Try on your device. Follow the{' '}
                  <Link href= {edgeServiceDetails.dockerComposeDocumentation} target='_blank' inline>
                    Get Started with Compose file
                  </Link>{' '}
                  instructions.
                </span>
              </Text>
              <Button onClick={()=>{downloadFile(edgeServiceDetails.dockerComposeLink)}} kind="secondary">Download Compose File</Button>
            </Stack> :null}
            {edgeServiceDetails.helmCommand && edgeServiceDetails.helmCommand.length > 0 && edgeServiceDetails.helmDocumentation && edgeServiceDetails.helmDocumentation.length > 0 ? <Stack className="sidebar-content-stack">
              <label className="spark-heading-size-xs">Helm* Pull</label>
              <Text>
                <span>
                  Get a Helm chart from Docker* Hub. Follow the{' '}
                  <Link href={edgeServiceDetails.helmDocumentation} target='_blank' inline>
                    Get Started with a Helm* chart
                  </Link>{' '}
                  instructions.
                </span>
              </Text>
              <CodeSnippet align="top" type="single" feedback="Copied to clipboard">
                {edgeServiceDetails.helmCommand}
              </CodeSnippet>
            </Stack>:null}
            {edgeServiceDetails.dockerCommand && edgeServiceDetails.dockerCommand.length > 0 && edgeServiceDetails.dockerDocumentation && edgeServiceDetails.dockerDocumentation.length > 0 ? <Stack className="sidebar-content-stack">
              <label className="spark-heading-size-xs">Docker* Pull</label>
              <Text>
                <span>
                  Get a container image from Docker* Hub. Follow the{' '}
                  <Link inline href={edgeServiceDetails.dockerDocumentation} target='_blank'>
                    Get Started with a container
                  </Link>{' '}
                  instructions.
                </span>
              </Text>
              <CodeSnippet align="top" type="single" feedback="Copied to clipboard">
                {edgeServiceDetails.dockerCommand}
              </CodeSnippet>
            </Stack> : null}
            {edgeServiceDetails.publicGithubUrl && edgeServiceDetails.publicGithubUrl.length > 0 ? <Stack className="sidebar-content-stack">
              <label className="spark-heading-size-xs">Source Code</label>
              <Text>
                <span>
                  Access source code and instructions to get started on GitHub*.
                </span>
              </Text>
              <Button href={edgeServiceDetails.publicGithubUrl} target='_blank' kind="secondary" tooltipPosition="bottom" iconDescription="icon description" renderIcon={() => <i className="spark-icon spark-icon-regular spark-icon-arrow-large-up-right" style={{
                paddingLeft: 'var(--cds-spark-theme-spacing-03)',
                fontFamily: 'spark-icon'
              }} />}>
                Go to GitHub
              </Button>
            </Stack> : null}
              </Sidebar> :  
              (edgeServiceDetails.recipeClass && edgeServiceDetails.recipeClass === 'ITEP Solutions') ? <Sidebar title="Get Started" titleLevel={3}>
                <Stack className="sidebar-content-stack">
                  <label className="spark-heading-size-xs">Schedule a demonstration</label>
                  <Text>
                    <span>
                      Contact your Intel representative to schedule a demonstration.
                    </span>
                  </Text>
                </Stack>
              </Sidebar> : <Sidebar title="Get Started" titleLevel={3}>
                  <Stack className="sidebar-content-stack">
                    <label className="spark-heading-size-xs">Download</label>
                    <Text>
                      <span>
                        Install and run on your device.
                      </span>
                    </Text>                  
                    {edgeServiceDetails.licenceType === 'clickToAccept' || (edgeServiceDetails.licenceType === null || edgeServiceDetails.licenceType === "" || edgeServiceDetails.licenceType === undefined) ?
                      <Button kind="secondary" href={`${edgeServiceDetails.selectorToolRecipeId}`}> Sign In </Button>
                      :
                      edgeServiceDetails.licenceType === 'cnda' ?
                      <Button kind="secondary" href={`${edgeServiceDetails.selectorToolRecipeId}`}> Request Access </Button> : null}
                  </Stack>
                </Sidebar>           
              : null}
          {showDocumentation ? <Sidebar title="Learn More" titleLevel={3}>
            {showUserGuide ? <Stack className="sidebar-content-stack">
              <Link href={edgeServiceDetails.userGuideLink} target="_blank">
                <span style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem'
                }}>
                  <i className="spark-icon spark-icon-arrow-large-up-right spark-icon-regular" style={{
                    fontFamily: 'spark-icon'
                  }} />
                  Documentation
                </span>
              </Link>
            </Stack> : null}
            {showAPIDocLink  ? <Stack className="sidebar-content-stack">
              <Link href={edgeServiceDetails.apiDocumentationLink} target="_blank">
                <span style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem'
                }}>
                  <i className="spark-icon spark-icon-arrow-large-up-right spark-icon-regular" style={{
                    fontFamily: 'spark-icon'
                  }} />
                  API Reference Manual
                </span>
              </Link>
            </Stack> : null}
            {showBenchmarkLink  ? <Stack className="sidebar-content-stack">
              <Link href={edgeServiceDetails.benchmarkLink } target="_blank">
                <span style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem'
                }}>
                  <i className="spark-icon spark-icon-arrow-large-up-right spark-icon-regular" style={{
                    fontFamily: 'spark-icon'
                  }} />
                 Benchmarks
                </span>
              </Link>
            </Stack> : null}
          </Sidebar> : null}
          {githubLanguages ? <Sidebar title="Details" titleLevel={3}>
            <Stack className="sidebar-content-stack">
              <Text size="l">Lenguages</Text>
              <ul className='top-block-right-sidebar-details-body-content-list-text-value'>
                {Object.entries(githubLanguages).map(
                  ([key, val]) => (
                    <li key={key}>
                      {key}: {val}
                    </li>
                  )
                )}
              </ul>
            </Stack>
          </Sidebar> : null}
        </Section>
      </aside>
    </Column>
  </Grid>;
  return (

    <>
      <Header />
      <div className="overview-header">
        <div className="back-section">
          <div className="back-button">
            <Link tabIndex="0" className="back-button-link" onClick={handleBackClick}>
              <i className="spark-icon spark-icon-light spark-icon-chevron-small-left back-button-icon"></i>
              <p className="back-button-text">Back</p>
            </Link>
          </div>

        </div>
      </div>
      {!isLoading ?
        <div data-theme="tiber-white">
          {headerBannerComponent}
          <main className="overview cds--content">
            {menuTab}
            <FlexGrid condensed>
              <Row>
                <Column lg={16} md={8} sm={4}>
                  {menuMobileComponent}
                </Column>
              </Row>
              <Row>
                <Column lg={16} md={8} sm={4}>
                  {overviewComponent}
                </Column>
              </Row>
              <Row>
                <Column lg={16} md={8} sm={4}>
                  {edgeServiceDetails.builtWith && edgeServiceDetails.builtWith.length > 0 ? (<div  ref={builtWithRef} id="builtwith"><FoundationalComponent
                    dataList={edgeServiceDetails.builtWith}
                    subTitle={"Select from the modular components included in the solution."}
                    heading={"Built with Foundational Components"}
                  /></div>) : ''}
                </Column>
              </Row>
            </FlexGrid>
          </main>
        </div>
        : <Loading data-theme="dark" data-theme-colors="tiber" />}
    </>

  );
};

export default Overview;
