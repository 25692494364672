import {
    Column,
    FlexGrid,
    Row,
    Button,
    Grid,
    Link,
    ListItem,
    Tag,
    UnorderedList,
    Section, Stack
} from '@carbon/react';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import { HeroBanner } from '../common/HeroBanner';
import Card from '../common/CardUsability';
import './Collections.scss';
import Heading from './Heading';
import { Text } from '../common/Text'
import Sidebar from '../common/Sidebar';
import { getDescription, getName, getIcon } from '../../common/utility';

export const Collections = ({ cards, tagArray, collection }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [viewOption, setViewOption] = useState('grid');
    const getLinkStyle = (hash) => {
        return location.hash === hash ||
            (location.hash === '' && hash === '#overview')
            ? {
                borderBottom: '2px #16CFB1 solid',
                color: '#161616',
                fontSize: '14px',
                fontFamily: 'IntelOne Text',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0.16px',
                wordWrap: 'break-word',
            }
            : {
                borderBottom: '2px #C6C6C6 solid',
                color: '#525252',
                fontSize: '14px',
                fontFamily: 'IntelOne Text',
                fontWeight: '400',
                lineHeight: '18px',
                letterSpacing: '0.16px',
                wordWrap: 'break-word',
            };
    };
    const headerBannerComponent = <HeroBanner>
        <Stack gap={1}>
            <Heading style={{
                color: 'var(--cds-text-inverse)'
            }} size="l">
                {collection.heading}
            </Heading>
            <Stack gap={1} orientation="horizontal" className='content-tags'>
                <Tag size="sm" className='overview_icon_tag'> <i
                    className={`spark-icon spark-icon-${getIcon(collection.contentType)} spark-icon-light overview_icon`}
                    style={{ fontFamily: 'spark-icon', fontSize: '24px' }}
                />
                </Tag>
                {tagArray.map((item, idx) => (
                    <Tag
                        type={item.type}
                        key={idx}
                    >
                        {item.text}
                    </Tag>
                ))}
            </Stack>
        </Stack>
    </HeroBanner>

    const menuMobileComponent = <Grid style={{
        rowGap: 'var(--cds-spark-theme-spacing-01)'
    }} className="mobile-menu">
        <nav style={{
            width: 'max-content'
        }}>
            <Column lg={16} md={8} sm={4}>
                <Link href="#overview">Overview</Link>
            </Column>
            <Column lg={16} md={8} sm={4}>
                {cards.length > 0 ?
                <Link href="#incollection">In this Collection</Link> :null}
            </Column>
        </nav>
    </Grid>;
    const menuTab = <nav className="desktop-menu">
        <Button kind='secondary' href="#overview">Overview</Button>
        {cards.length > 0 ?
        <Button kind='secondary' href="#incollection">
            In this Collection
        </Button> : null }
    </nav>;
    const overviewComponent = <Grid style={{
        gap: 'var(--cds-spark-theme-spacing-05)'
    }}>
        <Column lg={{
            span: 12
        }} md={5} sm={4}>
            <Stack gap={5}>
                <Section level={2} className="section-top-gap" id="overview">
                    <Heading color='primary' size="m">Overview</Heading>
                    <Stack gap={5}>
                        {collection.overview}
                    </Stack>
                </Section>
            </Stack>
        </Column>
        <Column lg={{
            span: 4
        }} md={3} sm={4}>
            <aside>
                <Section level={2} className="section-top-gap" id="getStarted">
                    <Heading className="screenreader">How to Get Started</Heading>
                    <Sidebar>
                        <Stack className="sidebar-content-stack">
                            <Text size="m" color="secondary">
                                Author
                            </Text>
                            <Text size="m" color="primary">
                                Intel
                            </Text>
                        </Stack>

                        <Stack className="sidebar-content-stack">
                            <Text size="m" color="secondary">
                                Updated
                            </Text>
                            <Text size="m" color="primary">
                                {collection.modifiedOn}
                            </Text>
                        </Stack>

                        <Stack className="sidebar-content-stack">
                            <Text size="m" color="secondary">
                                Version
                            </Text>
                            <Text size="m" color="primary">
                                {collection.version}
                            </Text>
                        </Stack>
                    </Sidebar>
                    <Sidebar title="Learn More" titleLevel={3}>
                        {collection.links.map((item, idx) => (
                            <Link href={item.url} target="_blank">
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '.5rem'
                                }}>
                                    <i className="spark-icon spark-icon-arrow-large-up-right spark-icon-regular" style={{
                                        fontFamily: 'spark-icon'
                                    }} />
                                    {item.label}
                                </span>
                            </Link>

                        ))}
                    </Sidebar>
                </Section>
            </aside>
        </Column>
    </Grid>;
    const inCollection = <div id="incollection" style={{ paddingInline: '1rem' }}><header
        style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
        }}
    >
        <Heading size="m">In this Collection</Heading>
        <section
            style={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: '1.25rem',
            }}
        >
            <i
                onClick={() => setViewOption('list')}
                style={{
                    color:
                        viewOption === 'grid'
                            ? 'var(--icon-on-color-disabled)'
                            : '#0F9B8F',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                }}
                className="spark-icon spark-icon-regular spark-icon-list"
            />
            <i
                onClick={() => setViewOption('grid')}
                style={{
                    color:
                        viewOption === 'grid'
                            ? '#0F9B8F'
                            : 'var(--icon-on-color-disabled)',
                    fontSize: '1.5rem',
                    cursor: 'pointer',
                }}
                className="spark-icon spark-icon-regular spark-icon-grid"
            />
        </section>
    </header>
        <p className="regular-Body-L-Subtitle-M-font-size-100">
            Select from curated components, software tools,
            reference implementations, and sample
            applications.
        </p>
        <Grid
            condensed
            style={{ gap: '20px', marginRight: '54px', paddingTop: '1rem' }}
        >
            {cards.map((card, idx) => (
                <Column
                    key={idx}
                    lg={viewOption === 'grid' ? 4 : 16}
                    md={viewOption === 'grid' ? 4 : 8}
                    sm={4}
                >
                    <Card
                        heading={getName(card.heading)}
                        contentType={card.contentType}
                        icon={getIcon(card.contentType)}
                        iconGradient
                        iconGradientColor="linear-gradient(
                                            180deg,
                                            #5400C0,
                                            #057aff,
                                            #16cfb1
                                            )"
                        children={getDescription(card.description)}
                        footer={card.modifiedOn}
                        href={card.link} target="_blank"
                    />
                </Column>
            ))}
        </Grid></div>
    return (
        <>
            <Header />
            <div className="overview-header">
                <div className="back-section">
                    <div className="back-button">
                        <Link tabIndex="0" className="back-button-link" onClick={() => navigate('/')}>
                            <i className="spark-icon spark-icon-light spark-icon-chevron-small-left back-button-icon"></i>
                            <p className="back-button-text">Back</p>
                        </Link>
                    </div>

                </div>
            </div>
            <div data-theme="tiber-white">
                {headerBannerComponent}
                <main className="overview cds--content">
                    {menuTab}
                    <FlexGrid condensed>
                        <Row>
                            <Column lg={16} md={8} sm={4}>
                                {menuMobileComponent}
                            </Column>
                        </Row>
                        <Row>
                            <Column lg={16} md={8} sm={4}>
                                {overviewComponent}
                            </Column>
                        </Row>
                        <Row>
                            <Column lg={16} md={8} sm={4}>
                            { cards.length > 0 ?
                                inCollection : null }
                            </Column>
                        </Row>
                    </FlexGrid>
                </main>
            </div>

        </>
    );
};
export default Collections;