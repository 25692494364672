import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Column, Loading } from "@carbon/react"
import CardLayout from "../CardLayout";
import Filter from '../Filter';
import Megabar from "../Megabar";
import { getAllItems } from "../../redux/reducers/slice/homePageSlice";
import "./SoftwareCatalog.scss";
import Header from '../common/Header';
import { HeroBanner } from "../common/HeroBanner";
import LearnMore from '../LearnMore';

const SoftwareCatalog = () => {
    const { isLoading, catalogList } = useSelector((state) => state.homePageCatalog);
    const dispatch = useDispatch();
    const args = {
        variant: 'primary',
        children:
        <Grid condensed={true} style={{ gap: '16px', alignItems: 'center', padding: 0 }} ><Column lg={12} md={8} sm={4}><p className="catalog-banner-text" >Discover composable ingredients, services and tools for building, deploying, and managing edge AI solutions optimized for Intel® AI Edge Systems.</p></Column></Grid>
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        if(!catalogList || !catalogList.length > 0 ){
            dispatch(getAllItems());
        }
    }, []);
    return (
        <>
        <Header />
        <div><HeroBanner {...args} /></div>
        {isLoading? <Loading data-theme="dark" data-theme-colors="tiber" />:<Grid condensed className="catalog-layout-grid" style={{
            gap: '32px'
        }}>
            <Column lg={4} md={2} sm={4}>
                <Filter />
            </Column>
            <Column lg={12} md={6} sm={4}>
                <Megabar />
                <CardLayout />
            </Column>
        </Grid>}
        {/* <LearnMore /> */}
        </>
    );
};

export default SoftwareCatalog;
