import React from 'react';
import { Heading, Section, Stack } from '@carbon/react';
import PropTypes from 'prop-types';
import './Sidebar.scss';

const Sidebar = ({
    style,
    children,
    title,
    titleSize = 's',
    titleLevel = 1,
    ...rest
}) => {
    return (
        <Stack className="sidebar-block" style={style} {...rest}>
            {title && (
                <Section level={titleLevel} className="sidebar-title">
                    <Heading
                        className={`spark-heading-size-${titleSize}`}
                        style={{
                            padding: 'var(--cds-spark-theme-spacing-03) var(--cds-spark-theme-spacing-06)',
                            gap: 'var(--cds-spark-theme-spacing-06)'
                        }}
                    >
                        {title}
                    </Heading>
                </Section>
            )}
            <Stack className="sidebar-content">{children}</Stack>
        </Stack>
    );
};
Sidebar.propTypes= {
    /** Specifies a className for the element */
    className: PropTypes.string,
    /** Specifies inline styles for the element. */
    style: PropTypes.object,
    /** Title for the sidebarblock. The first block usually doesn't have a title. */
    title: PropTypes.string,
    children: PropTypes.node,
    /** Title sizes according to spark theme. */
    titleSize: PropTypes.oneOf(['2xl' , 'xl', 'l', 'm', 'ms', 's', 'xs']),
    /** Title level: H1, H2, .... */
    titleLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6])
}
export default Sidebar