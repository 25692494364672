import {
    Link,
    ListItem,
    UnorderedList,
} from '@carbon/react';
import Heading from '../components/Collections/Heading';
const {
    catalogUi: { maxLengthMicroserviceName, maxLengthMicroserviceDescription },
    iconMapping,
} = require("../constants/config");
function getTextFromHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.innerText ?? "";
}
export const getName = (name) => {
    return name?.length > maxLengthMicroserviceName
        ? `${name.substring(0, maxLengthMicroserviceName)}...`
        : name;
};
export const encodeURIComponentModified = (str) => {
    str = str.replace(/%/g, '%25');
    return encodeURIComponent(str);
};
export const getDescription = (description) => {
    let desc = getTextFromHtml(description);
    return desc?.length > maxLengthMicroserviceDescription
        ? `${desc.substring(0, maxLengthMicroserviceDescription)}...`
        : desc;
};
export const formatDate = (dateValue) => {
    const modifiedOn = dateValue.split('T')[0];
    const date = modifiedOn.split('-');
    const year = date[0];
    const month = date[1];
    const day = date[2];
    return ("Updated: " + month + '/' + day + '/' + year);
}
export const getIcon = (contentType) => {
    let item = iconMapping.filter(function (el) {
        return el.contentType === contentType
    });
    return (item && item.length > 0 && item[0].icon) ? item[0].icon : "cube-detached-left"
}
/* Download docker compose to user system */
const parseGithubUrl = (githubUrl, branch, fileLocation) => {
    let repo, owner
    if (githubUrl.endsWith('.git')) {
        githubUrl = githubUrl.replace('.git', '')
    }
    if (githubUrl.includes('/tree/')) {
        const githubUrlArray = githubUrl.split('tree')
        const repoUrl = githubUrlArray[0].split('/')
        owner = repoUrl[3]
        repo = repoUrl[4]
        const pathUrlArr = githubUrlArray[1].split('/')
        const pathUrlArrSpliced = pathUrlArr.splice(0, 2)
        branch = pathUrlArrSpliced[1]
        pathUrlArrSpliced.push(pathUrlArr.join('/'))
        fileLocation = pathUrlArrSpliced[2]
    } else if (githubUrl.includes('/blob/')) {
        const githubUrlArray = githubUrl.split('blob')
        const repoUrl = githubUrlArray[0].split('/')
        owner = repoUrl[3]
        repo = repoUrl[4]
        const pathUrlArr = githubUrlArray[1].split('/')
        const pathUrlArrSpliced = pathUrlArr.splice(0, 2)
        branch = pathUrlArrSpliced[1]
        pathUrlArrSpliced.push(pathUrlArr.join('/'))
        fileLocation = pathUrlArrSpliced[2]
    } else {
        const githubUrlArray = githubUrl.split('/')
        owner = githubUrlArray[3]
        repo = githubUrlArray[4]
        branch = branch === undefined ? '' : branch
        fileLocation = fileLocation === undefined ? '' : fileLocation
    }
    return [owner, repo, branch, fileLocation]
}

export const downloadFile = async (dockercomposeLink) => {
    const [owner, repo, branch, fileLocation] = parseGithubUrl(dockercomposeLink)
    const url = `https://raw.githubusercontent.com/${owner}/${repo}/${branch}/${fileLocation}`;
    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch the file: ${response.statusText}`);
        }

        const blob = await response.blob();
        const urlObject = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = urlObject;
        link.download = 'docker-compose.yml';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(urlObject);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

export const metroCards = [
    {
	heading: 'Intel® Edge Device Enablement Framework (EEF)',
        contentType: 'Software Package',
        description: 'The Intel® Edge Device Enablement Framework (EEF) delivers a set of curated, validated infrastructure stacks (aka profiles), providing a runtime for edge applications.',
        modifiedOn: 'Updated: 03/28/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=edge-device-enablement-framework-24.11.09',
    },
    {
        heading: 'Metro AI Suite Software Development Kit',
        contentType: 'Software Development Kit',
        description: 'A comprehensive and modular toolkit for accelerated media processing and AI inference, designed to fast-track the development of visual AI solutions.',
        modifiedOn: 'Updated: 03/28/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-sdk-for-software-developers',
    },
    {
        heading: 'Metro AI Suite Device Qualification',
        contentType: 'Technology Evaluation Kit',
        description: 'Provides media, AI, and end-to-end pipeline benchmark tools for partners to capture video analytics performance and co-market with Intel.',
        modifiedOn: 'Updated: 03/28/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-device-qualification-for-hardware-builder',
    },
    {
        heading: 'Sensor Fusion for Traffic Management',
        contentType: 'Reference Implementation',
        description: 'A multi-modal reference implementation for traffic management, enabling partners to blend camera and radar sensor inputs to accurately monitor traffic conditions.',
        modifiedOn: 'Updated: 12/26/2024',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-sensor-fusion-for-traffic-management',
    },
    {
        heading: 'Image Search by Text',
        contentType: 'Reference Implementation',
        description: 'Multi-modal Large Vision Model-based reference implementation. Retrieves image results based on user queries from a local database (e.g. “Find pictures with luggage”)',
        modifiedOn: 'Updated: 12/26/2024',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-image-search-by-text',
    },
    {
        heading: 'Visual Question Answering',
        contentType: 'Reference Implementation',
        description: 'Gen AI-based reference implementation answers questions based on images for image captioning and retrieval use-cases (e.g. “Is there Luggage in the image”)',
        modifiedOn: 'Updated: 12/26/2024',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-visual-question-answering',
    },
    {
        heading: 'Interactive Digital Avatar',
        contentType: 'Reference Implementation',
        description: 'A Windows-based demo leveraging LVM, Automatic Speech Recognition (ASR), and Text-to-Speech (TTS) to facilitate real-time voice interactions with customers.',
        modifiedOn: 'Updated: 12/26/2024',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-interactive-digital-avatar',
    },
    {
        heading: 'Video Processing Software Development Kit',
        contentType: 'Software Development Kit',
        description: 'Reference implementation and SDK that addresses video processing acceleration needs for video-based solutions, such as NVR and video matrix displays.',
        modifiedOn: 'Updated: 12/26/2024',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-video-processing-software-development-kit',
    },
    {
        heading: 'Edge Video Infrastructure: Image Search by Image',
        contentType: 'Reference Implementation',
        description: 'A high-end video processing solution, with feature matching, inference, structuring, clustering, and storage services. Includes a Traffic Search reference implementation.',
        modifiedOn: 'Updated: 12/26/2024',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=metro-ai-suite-image-search-by-image-server',
    },
    {
        heading: 'Search Image by Image Sample Application',
        contentType: 'Sample Application',
        description: 'A modular and practical sample application for searching live or recorded camera feeds using an image, enabling object matching with location, timestamp, and confidence score details.',
        modifiedOn: 'Updated: 03/27/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=search-image-by-image',
    },
    {
        heading: 'Visual Pipeline and Platform Evaluation Tool',
        contentType: 'Technology Evaluation Kit',
        description: 'A versatile and intuitive tool for simplifying hardware selection for AI workloads, allowing you to configure workload parameters, benchmark performance, and analyze key metrics like throughput, CPU, and GPU usage.',
        modifiedOn: 'Updated: 03/27/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=visual-pipeline-and-platform-evaluation-tool',
    },
    {
        heading: 'Smart Parking',
        contentType: 'Sample Application',
        description: 'Effortlessly manage parking spaces with AI-driven video analytics for real-time insights and enhanced efficiency.',
        modifiedOn: 'Updated: 03/28/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=smart-parking',
    },

    {
        heading: 'Loitering Detection',
        contentType: 'Sample Application',
        description: 'Effortlessly monitor and manage areas with AI-driven video analytics for real-time insights and enhanced security.',
        modifiedOn: 'Updated: 03/28/2025',
        link: 'https://edgesoftwarecatalog.intel.com/details/?microserviceType=recipe&microserviceNameForUrl=loitering-detection',
    },

];

export const metroTagArray = [
    { type: 'gray', text: 'Collection' },
    { type: 'gray', text: 'AI and Machine Learning' },
    { type: 'gray', text: 'Generative AI' },
    { type: 'gray', text: 'Safety and Security' },
    { type: 'gray', text: 'Transportation' },
];

export const metroCollection = {
    heading: 'Metro AI Suite',
    modifiedOn: '03/28/2025',
    version:'1.5',
    contentType: 'Collection',
    links: [{ url: 'https://www.intel.com/content/www/us/en/developer/topic-technology/edge-5g/edge-solutions/hardware.html', label: 'Recommended Hardware Catalog' },
    { url: 'https://networkbuilders.intel.com/communities/government/video-ai-cities', label: 'Video & AI Cities Solutions Catalog' }],
    overview: <><p className="regular-Body-M-Subtitle-S-font-size-75">
        The Metro AI Suite is an
        application framework with libraries, tools, and
        reference implementations, enabling partners to
        create AI solutions in the Video Safety and
        Security, Transportation, and Government Edge
        markets.
    </p>
        <Heading size="s">Benefits</Heading>
        <p className="regular-Body-L-Subtitle-M-font-size-100">
            AI System qualification and co-marketing
            opportunities for hardware builders.
        </p>
        <UnorderedList
            style={{ color: 'var(--cds-text-secondary)' }}
        >
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Demonstrate the capabilities of your Intel®
                AI System with the Metro AI Suite
                reference implementations.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Use the Metro AI Suite Device Qualification
                Tool to verify hardware performance and create system
                qualification reports.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Feature your qualified systems in{' '}
                <Link
                    href="https://www.intel.com/content/www/us/en/developer/topic-technology/edge-5g/edge-solutions/hardware.html"
                    target="_blank"
                    style={{}}
                >
                    Intel® Edge Software Recommended Hardware
                </Link>{' '}
                for co-marketing and ISV match-making
                opportunities.
            </ListItem>
        </UnorderedList>
        <p className="regular-Body-L-Subtitle-M-font-size-100">
            Accelerated development for solution builders.
        </p>
        <UnorderedList
            style={{ color: 'var(--cds-text-secondary)' }}
        >
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Get the Metro AI Suite Software Development
                Kit to use OpenVINO™ Toolkit, Intel® Deep Learning Streamer
                (Intel® DL Streamer), Intel® oneAPI and other tools, libraries,
                and microservices for media analytics and AI performance optimization.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Use reference implementations as blueprints
                for developing AI solutions optimized for
                Intel® hardware.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Promote your Metro AI Suite solutions in{' '}
                <Link
                    href="https://networkbuilders.intel.com/communities/government/video-ai-cities"
                    target="_blank"
                >
                    Intel® Video & AI Cities
                </Link>{' '}
                to reach a broader audience.
            </ListItem>
        </UnorderedList>
        <Heading size="s">Platforms Supported</Heading>
        <p className="regular-Body-M-Subtitle-S-font-size-75">
            The Metro AI Suite supports a variety of
            Intel® platforms, including Intel Atom®, Intel® Core™,
            Intel® Core™ Ultra, Intel® Xeon® and Intel® Arc™ GPUs
            for light to heavy AI workloads.
        </p></>
}

export const retailCollection = {
    heading: 'Intel® Retail AI Suite',
    modifiedOn: '3/13/2025',
    version: '1.0',
    contentType: 'Collection',
    links: [{ url: 'https://github.com/intel-retail', label: 'Intel Retail Github' },
    { url: 'https://www.intel.com/content/www/us/en/developer/topic-technology/edge-5g/tools/automated-self-checkout-benchmark-results.html', label: 'Performance data for consumer industries edge use cases' },
     { url: 'https://www.intel.com/content/www/us/en/developer/articles/reference-implementation/automated-self-checkout.html', label: 'Automated Self-Checkout Sample App' },
     { url: 'https://www.intel.com/content/www/us/en/developer/articles/reference-implementation/loss-prevention.html', label: 'Loss Prevention Sample App' }],
    overview: <><p className="regular-Body-M-Subtitle-S-font-size-75">
        The Intel® Retail AI Suite is an open-source application framework designed to accelerate 
        AI workload evaluation and hardware selection for point-of-sale use cases at the edge. 
        This framework helps retailers assess device configurations across Intel® product generations to 
        enhance decision-making and reduce the total cost of ownership.
    </p>
        <Heading size="s">Benefits</Heading>
        <UnorderedList
            style={{ color: 'var(--cds-text-secondary)' }}
        >
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Sample applications to evaluate the potential of AI workloads 
                for POS use cases across multi-modalities and device configurations.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                End-to-end workload performance (e.g., throughput, power efficiency) 
                for hardware evaluation and scaling.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Guidance for hardware options to meet evolving retailer needs.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Flexibility to evaluate device configurations across different Intel product generations.
            </ListItem>
            <ListItem className="regular-Body-M-Subtitle-S-font-size-75">
                Development acceleration with OpenVINO and Intel Deep Learning Streams (Intel DL Streamer).
            </ListItem>
        </UnorderedList>
        <Heading size="s">Platforms Supported</Heading>
        <p className="regular-Body-M-Subtitle-S-font-size-75">
            The Intel® Retail AI Suites supports a variety of Intel® hardware, software, and other open-source components.
        </p></>
}
export const retailTagArray = [
    { type: 'gray', text: 'Collection' },
    { type: 'gray', text: 'AI and Machine Learning' },
    { type: 'gray', text: 'Generative Artificial Intelligence (GenAI)' },
    { type: 'gray', text: 'Retail'},
];



