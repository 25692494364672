import './Divider.scss'
import React from 'react'
import PropTypes from 'prop-types'


const Divider = ({
    spacing = 'none',
    borderHeight = '2px',
    borderColor = 'subtle',
    style,
    ...rest
}) => {
    const marginCheck =
        spacing === 'none' ? null : { marginBlock: `var(--cds-spark-theme-spacing-${spacing})` }
    return (
        <div
            style={{
                ...marginCheck,
                blockSize: borderHeight,
                backgroundColor: `var(--border-${borderColor}-01)`,
                ...style
            }}
            {...rest}
        />
    )
}

Divider.propTypes = {
    spacing: PropTypes.oneOf([
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', 'none'
    ]),
    borderColor: PropTypes.oneOf(['subtle', 'strong']),
    borderHeight: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
}

export default Divider