import "./FoundationalComponent.scss";
// import { Link as RouterLink } from "react-router-dom";
import {
    Column,
    Grid,
} from '@carbon/react';
import Card from '../CardUsability'
import { useState } from 'react';
import Heading from "../../Collections/Heading"
import {encodeURIComponentModified, formatDate, getDescription, getIcon, getName} from "../../../common/utility"
const FoundationalComponent = (props) => {
    const [viewOption, setViewOption] = useState('grid');
    let dataList = props.dataList;
    let subtitle = props.subTitle;
    let heading = props.heading;

    return (
        <Grid
        condensed
        id="in-this-collection"
        style={{ gap: '32px', marginTop: '40px' }}
    >
        <Column
            lg={16}
            md={8}
            sm={4}
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
            }}
        >
            <header
                style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                }}
            >
                <Heading size="m">{heading}</Heading>
                <section
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        gap: '1.25rem',
                    }}
                >
                    <i
                        onClick={() => setViewOption('list')}
                        style={{
                            color:
                                viewOption === 'grid'
                                    ? 'var(--icon-on-color-disabled)'
                                    : '#0F9B8F',
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                        }}
                        className="spark-icon spark-icon-regular spark-icon-list"
                    />
                    <i
                        onClick={() => setViewOption('grid')}
                        style={{
                            color:
                                viewOption === 'grid'
                                    ? '#0F9B8F'
                                    : 'var(--icon-on-color-disabled)',
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                        }}
                        className="spark-icon spark-icon-regular spark-icon-grid"
                    />
                </section>
            </header>
            <p className="regular-Body-L-Subtitle-M-font-size-100">
                {subtitle}
            </p>
            <Grid
                condensed
                style={{ gap: '20px', marginRight: '54px' }}
            >
                {dataList.map((card, idx) => (
                    <Column
                        key={idx}
                        lg={viewOption === 'grid' ? 4 : 16}
                        md={viewOption === 'grid' ? 4 : 8}
                        sm={4}
                    >
                        <Card
                            heading={getName(card.displayName)}
                            contentType={card.contentType}
                            icon={'cube-detached-left'}
                            iconGradient
                            iconGradientColor="linear-gradient(
                                                180deg,
                                              #5400C0,
                                              #057aff,
                                              #16cfb1
                                               )"
                            children={getDescription(card.microserviceDescription)}
                            footer={formatDate(card.modifiedOn)}
                            href={`/details/?microserviceType=${card.microserviceType}&microserviceNameForUrl=${encodeURIComponentModified(card.microserviceNameForUrl)}`} target="_blank"
                        />
                    </Column>
                ))}
            </Grid>
        </Column>
    </Grid>        
    );
};

export default FoundationalComponent;
