import "./Filter.scss";
import { Link, Tooltip, Checkbox, Heading } from "@carbon/react"
import { Divider } from "../common/Divider";
import {
  AddToDomainFilter,
  AddToIndustryFilter,
  AddToContentTypeFilter,
  removeFromContentTypeFilter,
  removeAllFilters,
  resetDomainFilter,
} from "../../redux/reducers/slice/filterSlice";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getIcon } from "../../common/utility"
const {
  toolTipMapping,
} = require("../../constants/config");

const Filter = (props) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredTopic, setFilteredTopic] = useState(null)
  const industry = useSelector((state) => state.filter.filter.industryFilter);
  const domain = useSelector((state) => state.filter.filter.domainFilter);
  const contentType = useSelector(
    (state) => state.filter.filter.contentTypeFilter
  );
  const industryFiltersArrayWithCount = useSelector(
    (state) => state.filter.filter.industryFiltersArrayWithCount
  );
  const domainFiltersArrayWithCount = useSelector(
    (state) => state.filter.filter.domainFiltersArrayWithCount
  );
  const contentTypeFiltersArrayWithCount = useSelector(
    (state) => state.filter.filter.contentTypeFiltersArrayWithCount
  );
  useEffect(() => {
    if(searchParams.get("topic")){
      dispatch(resetDomainFilter())
      setFilteredTopic(searchParams.get("topic"))
    } else {
      dispatch(resetDomainFilter())
    }
  }, [searchParams]);

  useEffect(() => {
    if(filteredTopic){      
      dispatch(AddToDomainFilter(filteredTopic));
    }
  }, [filteredTopic]);

  const industryFilterHandler = (value, event) => {
    if (value === industry) return;
    dispatch(AddToIndustryFilter(value));
  };
  const topicFilterHandler = (value, event) => {
    if (value === domain) return;
    dispatch(AddToDomainFilter(value));
  };
  const contentTypeFilterHandler = (data, e, value) => {
    if (value === contentType) return;
    if (data.checked) dispatch(AddToContentTypeFilter(value));
    else dispatch(removeFromContentTypeFilter(value));
  };
  const getTooltip = (contentType) => {
    let item = toolTipMapping.filter(function (el) {
      return el.contentType === contentType
    });
    return (item && item.length > 0 && item[0].toolTip) ? item[0].toolTip : contentType
  }
  const renderIcon = (contentType) => {
    let iconName = getIcon(contentType.name)
    return(
      <Tooltip
        align="top"
        enterDelayMs={100}
        label={getTooltip(contentType.name)}
        leaveDelayMs={300}
      >
        <div className="filter-items-label">
          <i
            className={`spark-icon spark-icon-${iconName} spark-icon-light`}
            style={{ fontFamily: 'spark-icon', fontSize: '16px' }}
          />
          <span className="filter-items-label-text">{contentType.name} ({contentType.count})</span>
        </div>
      </Tooltip>

    )
  }
  const clearAllFilters = () => {
    dispatch(removeAllFilters());
  };
  return (
    <div className="filterSideBar">
        <div className="filter-shell">
      <div className="filter-heading">
        <Heading className="filter-heading-text spark-heading-size-xs">
          Filter By
        </Heading>
      </div>
      <div className="divider">
        <Divider/>
      </div>
      {industry.length > 0 || domain.length > 0 || contentType.length > 0 ? (
        <Link className="clear-filter" onClick={clearAllFilters}>
          Clear All Filters
        </Link>     
      ) : null}

      <div className="labelDiv">
        <label className="label-text">Content Type</label>
      </div>
      <ul>
        {contentTypeFiltersArrayWithCount.map((contentT, index) => {
          return (
            <li className="filter-items" key={index} tabIndex="0">
              <Checkbox
                id={`checkbox-ct-${index}`}
                className={
                  contentType.includes(contentT.name)
                    ? "filter-selected"
                    : "filter-not-selected"
                }
                checked={contentType.includes(contentT.name)}
                labelText={renderIcon(contentT)}
                onChange={(e, data) => { contentTypeFilterHandler(data, e, contentT.name) }}
              />
            </li>
          );
        })}
      </ul>
      <div className="divider">
        <Divider/>
      </div>
      <div className="labelDiv">
        <label className="label-text">Topic</label>
      </div>
      <ul>
        {domainFiltersArrayWithCount.map((domainFilter, index) => {
          return (
            <li
              key={index} tabIndex="0"
              onClick={topicFilterHandler.bind(this, domainFilter.name)}
              className={
                domain.includes(domainFilter.name)
                  ? "filter-items filter-selected"
                  : "filter-items filter-not-selected"
              }
            >
              <span className="filter-items-label-text">{domainFilter.name} ({domainFilter.count})</span>
            </li>
          );
        })}
      </ul>
      <div className="divider">
        <Divider/>
      </div>
      <div className="labelDiv">
        <label className="label-text">Industry</label>
      </div>
      <ul>
        {industryFiltersArrayWithCount.map((industryFilter, index) => {
          return (
            <li
              key={index} tabIndex="0"
              onClick={industryFilterHandler.bind(this, industryFilter.name)}
              className={
                industry.includes(industryFilter.name)
                  ? "filter-items filter-selected"
                  : "filter-items filter-not-selected"
              }
            >
              <span className="filter-items-label-text">{industryFilter.name} ({industryFilter.count})</span>
            </li>
          );
        })}
      </ul>      
      </div>
    </div>
  );
};

export default Filter;
